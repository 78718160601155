.c-page-title {
  color: #fff;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 16px;
  text-shadow: 0 2px 12px #000, 0 6px 32px rgba(#000, 0.25);

  @include bp-xsmall {
    font-size: 30px;
  }

  @include bp-medium {
    font-size: 44px;
    margin-bottom: 20px;
    text-shadow: 0 2px 12px rgba(#000, 0.25), 0 6px 32px rgba(#000, 0.25);
  }

  @include bp-large {
    font-size: 56px;
    margin-bottom: 24px;
  }

  @include bp($min, '1200px') {
    font-size: 64px;
  }

  @include bp-full {
    font-size: 68px;
  }
}
