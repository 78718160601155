.c-figure__img {
  margin-bottom: 12px;

  .c-article & {
    width: 100%;
  }
}

.c-figure__caption {
  font-style: italic;
}
