.c-tabs-panels__tab {
  background-color: #eee;
  border: #ddd;
  border-radius: 20px;
  color: #333;
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 8px 12px;
  line-height: 1;
  margin-right: 12px;
  margin-bottom: 12px;
  text-decoration: none;
  text-transform: uppercase;

  &.is-expanded {
    background-color: $color-blood-orange;
    color: $color-white;
  }
}

.c-tabs-panels__panel {
  margin-top: 24px;

  &.is-hidden {
    display: none;
  }
}
