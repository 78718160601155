.u-case--lower {
  text-transform: lowercase;
}

.u-case--upper {
  text-transform: uppercase;
}

.u-case--reset {
  text-transform: none;
}
