.c-abstract {
  font-size: 20px;
  line-height: 1.2;

  @include bp-medium {
    font-size: 24px;
    margin-bottom: 32px;
  }
}

.c-abstract--margin {
  margin-bottom: 32px;

  @include bp-medium {
    margin-bottom: 56px;
  }
}
