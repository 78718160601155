/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-link;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: $color-hover;
    text-decoration: underline;
  }
}
