.c-separator {
  border: 0;
  border-bottom: 1px solid #ddd;
  margin: 16px 0;
}

.c-separator--content {
  margin: 16px 0 32px;
}

.c-separator--section {
  margin: 56px 0;
}
