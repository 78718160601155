.c-page-utility {
  padding: 32px 0;
  margin-bottom: 32px;

  @include bp-medium {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }
}

.c-page-utility--no-pb {
  padding-bottom: 0;
}
