.c-benefits-panel {
  background-color: $color-light-beige;
  padding-bottom: 32px;
  padding-top: 32px;
  text-align: center;

  @include bp-medium {
    padding-bottom: 56px;
    padding-top: 56px;
  }

  @include bp-large {
    padding-bottom: 72px;
    padding-top: 72px;
    text-align: left;
  }
}

.c-benefits-panel__content {
  max-width: 700px;

  @include bp-large {
    max-width: calc(1400px + 48px);
  }
}

.c-benefits-panel__cell {
  flex-basis: 100%;
  margin-bottom: 24px;
  max-width: 100%;

  @include bp-medium {
    margin-bottom: 40px;
  }
}

.c-benefits-panel__cell--image {
  @include bp-large {
    flex-basis: 45%;
    max-width: 45%;
  }
}

.c-benefits-panel__cell--content {
  @include bp-large {
    flex-basis: 55%;
    max-width: 55%;
  }
}

.c-benefits-panel__heading {
  margin-bottom: 16px;

  @include bp-large {
    margin-bottom: 24px;
  }
}

.c-benefits-panel__img {
  display: block;
  margin: 0 auto;
}

.c-benefits-panel__copy {
  font-size: 16px;
  margin-bottom: 24px;

  @include bp-xlarge {
    font-size: 18px;
  }
}

.c-benefits-panel__bottom {
  @include bp-medium {
    display: block;
  }

  @include bp-xlarge {
    align-items: center;
    display: flex;
  }
}

.c-btn.c-benefits-panel__cta {
  margin-bottom: 16px;
  white-space: nowrap;

  @include bp-medium {
    margin-bottom: 24px;
    margin-right: 24px;
  }
}

.c-benefits-panel__summary {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-benefits-panel__summary-copy {
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  margin-left: 16px;
  max-width: 360px;
  text-align: left;

  @include bp-medium {
    font-size: 15px;
    max-width: none;
    margin-left: 16px;
  }
}

.c-benefits-panel__summary-img {
  max-width: 38px;

  @include bp-large {
    max-width: 44px;
  }

  @include bp-xlarge {
    max-width: 56px;
  }

  @include bp-full {
    max-width: 68px;
  }
}
