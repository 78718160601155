.c-social-sharing--margin {
  margin-bottom: 24px;

  @include bp-medium {
    margin-bottom: 32px;
  }
}

.c-social-sharing__title {
  color: #a7a7a7;
  display: inline-block;
  font-size: 13px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 6px;
  margin-right: 8px;
}

.c-social-sharing__link {
  color: $color-blue;
  display: inline-block;
  font-size: 24px;
  line-height: 1;
  margin-right: 12px;
  text-decoration: none;
  vertical-align: middle;

  &:hover,
  &:focus {
    color: $color-blue;
    opacity: 0.8;
    text-decoration: none;
  }
}

.c-social-sharing__link--facebook {
  width: 18px;
}

.c-social-sharing__link--email {
  font-size: 20px;
}
