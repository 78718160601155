.c-article__header-image {
  display: block;
  margin-bottom: 24px;
}

.c-article__subtitle {
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 8px;

  @include bp-medium {
    font-size: 16px;
    margin-bottom: 12px;
  }
}

.c-article__title {
  font-size: 24px;
  margin-bottom: 8px;

  @include bp-medium {
    font-size: 40px;
    margin-bottom: 24px;
  }
}

.c-article__abstract {
  font-size: 16px;
  line-height: 1.78;
  margin-bottom: 16px;
  font-weight: 500;

  @include bp-medium {
    font-size: 22px;
    margin-bottom: 56px;
  }
}

.c-article__content {
  position: relative;
  margin-left: auto;
  margin-bottom: 48px;
  margin-right: auto;
  max-width: 640px;

  @include bp-large {
    margin-bottom: 96px;
    max-width: 720px;
  }

  h1, h2, h3, h4, h5, h6 { 
    @include font-standard;
    font-weight: 700;

    strong {
      font-weight: 700;      
    }
  }
}
