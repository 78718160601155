/* ==========================================================================
   #COLOR
   ========================================================================== */

.u-fill {
  background: $color-fill;
}

.u-color-orange {
  color: $color-orange;
}

.u-color-blood-orange {
  color: $color-blood-orange;
}

.u-color-green {
  color: $color-green;
}
