%icomoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icomoon {
  [class^="icon-"], [class*=" icon-"] {
    @content;
  }
}

@mixin icon($name) {
  .icon-#{$name} {
    &:before {
      @content;
    }
  }
}

@include icomoon {
  @extend %icomoon;
}

@font-face {
  font-family: 'icomoon';
  src: url('/dist/assets/fonts/icomoon.eot?16mev7');
  src: url('/dist/assets/fonts/icomoon.eot?16mev7#iefix') format('embedded-opentype'),
    url('/dist/assets/fonts/icomoon.ttf?16mev7') format('truetype'),
    url('/dist/assets/fonts/icomoon.woff?16mev7') format('woff'),
    url('/dist/assets/fonts/icomoon.svg?16mev7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-email:before {
  content: '\e924';
}

.icon-pinterest:before {
  content: '\e925';
}

.icon-print:before {
  content: '\e926';
}

.icon-arrow-down-long:before {
  content: '\e922';
}

.icon-external-link:before {
  content: '\e923';
}

.icon-caution:before {
  content: '\e91b';
}

.icon-chatbot:before {
  content: '\e91c';
}

.icon-download:before {
  content: '\e91d';
}

.icon-facebook:before {
  content: '\e91e';
}

.icon-help:before {
  content: '\e91f';
}

.icon-twitter:before {
  content: '\e921';
}

.icon-nav-cross:before {
  content: '\e91a';
}

.icon-arrow-down:before {
  content: '\e900';
}

.icon-arrow-left:before {
  content: '\e901';
}

.icon-arrow-right:before {
  content: '\e902';
}

.icon-arrow-up:before {
  content: '\e903';
}

.icon-bbq:before {
  content: '\e904';
}

.icon-beef:before {
  content: '\e905';
}

.icon-blender:before {
  content: '\e906';
}

.icon-chicken:before {
  content: '\e907';
}

.icon-cross:before {
  content: '\e908';
}

.icon-dairy:before {
  content: '\e909';
}

.icon-dashboard:before {
  content: '\e90a';
}

.icon-fish:before {
  content: '\e90b';
}

.icon-fruit:before {
  content: '\e90c';
}

.icon-frying-pan:before {
  content: '\e90d';
}

.icon-grains:before {
  content: '\e90e';
}

.icon-info:before {
  content: '\e90f';
}

.icon-magnifying-glass:before {
  content: '\e910';
}

.icon-microwave:before {
  content: '\e911';
}

.icon-oven:before {
  content: '\e912';
}

.icon-plus:before {
  content: '\e913';
}

.icon-pork:before {
  content: '\e914';
}

.icon-pot:before {
  content: '\e915';
}

.icon-sandwich-press:before {
  content: '\e916';
}

.icon-slow-cooker:before {
  content: '\e917';
}

.icon-tick:before {
  content: '\e918';
}

.icon-vegetables:before {
  content: '\e919';
}
