.c-featured-outlet {
  padding: 0 32px;

  @include bp-xsmall {
    padding: 0 40px;
  }

  @include bp-large {
    display: table;
    padding: 0;
    width: 100%;
  }
}

.c-featured-outlet__outlet {
  margin-bottom: 24px;
  text-align: center;

  @include bp-large {
    display: table-cell;
    padding-right: 24px;
  }

  @include bp-large {
    padding-right: 32px;
  }

  @include bp-xlarge {
    padding-right: 40px;
  }
}

.c-featured-outlet__outlet:last-child {
  padding-right: 0;

  @include bp-large {
    padding-right: 32px;
  }
}

.c-featured-outlet__outlet-img {
  width: 100%;
}
