.c-page-cta {
  background: $color-white;
  border-radius: $global-radius;
  margin-bottom: 48px;
  padding: 32px;
  text-align: center;

  .c-btn {
    display: block;

    @include bp-medium {
      display: inline-block;
    }
  }
}
