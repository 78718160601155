/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.c-btn {
  border-radius: $global-radius;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  margin: 0 0 8px;
  padding: 12px 16px 13px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: transform ease 200ms;
  transform: translateY(0);
  vertical-align: middle;

  @include bp-xsmall {
    font-size: 14px;
    padding: 12px 24px 13px;
  }

  @include bp-medium {
    padding: 14px 28px;
  }

  &:hover,
  &:focus {
    transform: translateY(-3px);
    text-decoration: none;
  }
}

.c-btn--nowrap {
  min-width: min-content;
  white-space: nowrap;
}

.c-btn--block {
  display: block;
}

.c-btn--green {
  background-color: $color-green;

  &,
  &:hover,
  &:active,
  &:focus {
    color: $color-white;
  }
}

.c-btn--primary {
  background: linear-gradient(to bottom, #acd829 10%, #60a207 100%);

  &,
  &:hover,
  &:active,
  &:focus {
    color: $color-white;
  }
}

.c-btn--standard {
  background-color: $color-charcoal;

  &,
  &:hover,
  &:active,
  &:focus {
    color: $color-white;
  }
}

.c-btn--color-light {
  background-color: $color-light-beige;

  &,
  &:hover,
  &:active,
  &:focus {
    color: $color-light-grey;
  }
}

.c-btn--color-white {
  background-color: $color-white;

  &,
  &:hover,
  &:active,
  &:focus {
    color: $color-charcoal;
  }
}

.c-btn--ghost {
  background-color: transparent;
  border: 1px solid $color-charcoal;
  color: $color-charcoal;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-charcoal;
    color: $color-white;
  }
}

.c-btn--pill {
  border-radius: 3px;
  font-size: 13px;
  padding: 4px 12px 2px 12px;
}

.c-btn--small {
  padding: 4px 12px;

  @include bp-medium {
    padding: 6px 16px;
  }

  [class^="icon"] {
    display: inline-block;
    font-size: 8px;
    vertical-align: middle;
  }

  .icon-arrow-right {
    margin-left: 4px;
  }

  .icon-arrow-left {
    margin-right: 4px;
  }
}

.c-btn--large {
  font-size: 15px;
  padding: 10px 16px;

  @include bp-medium {
    padding: 16px 28px;
  }
}

.c-btn--massive {
  padding: 16px;

  @include bp-xsmall {
    font-size: 15px;
  }

  @include bp-medium {
    font-size: 16px;
    padding: 16px 24px;
  }

  @include bp-large {
    font-size: 18px;
    padding: 18px 24px;
  }

  @include bp-xlarge {
    padding: 20px 32px;
  }
}
