/* ==========================================================================
   #SPACING
   @todo generate more generic classes and sizes
   ========================================================================== */

$utility-spacing: 1em;

.u-margin {
  margin: $utility-spacing;
}

.u-margin-top {
  margin-top: $utility-spacing;
}

.u-margin-right {
  margin-right: $utility-spacing;
}

.u-margin-bottom {
  margin-bottom: $utility-spacing;
}

.u-margin-left {
  margin-left: $utility-spacing;
}

.u-padding {
  padding: $utility-spacing;
}

.u-padding-top {
  padding-top: $utility-spacing;
}

.u-padding-right {
  padding-right: $utility-spacing;
}

.u-padding-bottom {
  padding-bottom: $utility-spacing;
}

.u-padding-left {
  padding-left: $utility-spacing;
}

.u-margin-bottom-0 {
  margin-bottom: 0;
}

.u-section-margin {
  margin-bottom: 24px;
  margin-top: 24px;
}

.u-section-margin--medium {
  margin-bottom: 32px;
  margin-top: 32px;

  @include bp-medium {
    margin-bottom: 56px;
    margin-top: 56px;
  }

  @include bp-large {
    margin-bottom: 88px;
    margin-top: 88px;
  }
}

.u-section-margin--large {
  margin-bottom: 56px;
  margin-top: 56px;

  @include bp-medium {
    margin-bottom: 80px;
    margin-top: 80px;
  }

  @include bp-large {
    margin-bottom: 120px;
    margin-top: 120px;
  }
}
