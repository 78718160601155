@mixin search-button {
  background: $color-blue;
  border-radius: $global-radius;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-width: 0;
  color: $color-white;
  cursor: pointer;
  font-size: 24px;
  height: 48px;
  line-height: 0;
  padding: 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 48px;
  z-index: 1;

  &:hover,
  &:focus,
  &:active {
    color: $color-white;
  }
}
