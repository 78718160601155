@mixin search-input {
  border: 1px solid #c1c1c1;
  border-right-width: 0;
  border-radius: $input-radius;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  color: $color-charcoal;
  flex-grow: 1;
  font-size: 16px;
  height: 48px;
  min-width: 0;
  padding: 8px 12px;

  @include bp-medium {
    padding: 8px 16px;
    font-size: 20px;
  }

  &::placeholder {
    color: #cac6c0;
  }

  &::-ms-clear {
    display: none;
  }
}
