.c-more-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  text-align: center;

  @include bp-medium {
    text-align: left;
    display: block;
    margin-top: 40px;
  }
}

.c-more-content > * {
  margin-bottom: 8px;

  @include bp-medium {
    margin-bottom: 0;
  }
}

.c-more-content__instruction {
  color: $color-charcoal;
  font-size: 13px;
  display: block;
  letter-spacing: 2px;
  text-transform: uppercase;

  @include bp-medium {
    display: inline-block;
    margin-right: 16px;
  }

  .c-superfood-carousel & {
    color: $color-white;
  }
}

.c-more-content .c-btn {
  display: inline-block;

  @include bp-medium {
    margin-right: 8px;
  }
}
