///* ========================================================================
//   #BREAKPOINTS
//   ======================================================================== */

$min: unquote('min-width');
$max: unquote('max-width');

@mixin bp-xsmall($operator: $min) {
  @media (#{$operator}: $bp-xsmall) {
    @content;
  }
}

@mixin bp-small($operator: $min) {
  @media (#{$operator}: $bp-small) {
    @content;
  }
}

@mixin bp-medium($operator: $min) {
  @media (#{$operator}: $bp-medium) {
    @content;
  }
}

@mixin bp-large($operator: $min) {
  @media (#{$operator}: $bp-large) {
    @content;
  }
}

@mixin bp-xlarge($operator: $min) {
  @media (#{$operator}: $bp-xlarge) {
    @content;
  }
}

@mixin bp-full($operator: $min) {
  @media (#{$operator}: $bp-full) {
    @content;
  }
}

@mixin bp($operator: $min, $pixels: $bp-medium) {
  @media (#{$operator}: $pixels) {
    @content;
  }
}

@mixin ie() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

