.c-benefit {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @include bp-small {
    justify-content: center;
  }

  @include bp-medium {
    justify-content: flex-start;
  }
}

.c-benefit__icon {
  color: $color-green;
  display: block;
  font-size: 28px;
  line-height: 1;
  margin-right: 16px;
  text-align: center;
  width: 30px;

  @include bp-xsmall {
    font-size: 32px;
    width: 40px;
  }

  @include bp-medium {
    font-size: 36px;
    width: 50px;
  }

  @include bp-xlarge {
    font-size: 40px;
  }
}

.c-benefit__heading {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 700;
  text-align: left;

  @include bp-xlarge {
    font-size: 17px;
  }
}
