.c-page-description {
  background: $color-white;
  font-size: 18px;
  font-weight: 500;
  margin: -50px auto 0 auto;
  max-width: 960px;
  padding: 16px;
  text-align: center;
  width: calc(100% - 32px);
  z-index: 2;

  @include bp-medium {
    font-size: 32px;
    margin: -50px auto 16px auto;
    padding: 32px;
    width: calc(100% - 48px);
  }
}
