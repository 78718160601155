.c-paging-link {
  background: $color-light-beige;
  border: 1px solid $color-light-beige;
  color: $color-dark-grey;
  cursor: default;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  border-radius: $global-radius;
  padding: 5px 10px;
  margin: 0 2px 8px;
  min-height: 32px;
  min-width: 32px;
  text-align: center;
  text-decoration: none;

  @include bp-xsmall {
    padding: 5px 12px;
  }

  @include bp-medium {
    margin: 0 4px 8px;
  }

  &[href]:hover,
  &[href]:focus {
    cursor: pointer;
    text-decoration: none;
  }

  [class^="icon"] {
    display: inline-block;
    font-size: 8px;
    vertical-align: middle;
  }

  .icon-arrow-left {
    @include bp-medium {
      margin-right: 4px;
    }
  }

  .icon-arrow-right {
    @include bp-medium {
      margin-left: 4px;
    }
  }
}

.c-paging-link--current {
  background: $color-blue;
  color: $color-white;
  font-weight: 700;
}

.c-paging-link--disabled {
  opacity: 0.5;

  &:hover,
  &:focus {
    color: $color-dark-grey;
  }
}

.c-paging-link--prev {
  margin-left: 0;
}

.c-paging-link--next {
  margin-right: 0;
}

.c-paging-link__text {
  display: none;

  @include bp-medium {
    display: inline-block;
  }
}
