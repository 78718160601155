.c-other-outlets {
  background: #f7f7f7;
  border-radius: $global-radius;
  display: flex;
  flex-wrap: wrap;
  padding: 24px 14px;

  @include bp-medium {
    padding: 24px;
  }

  @include bp-large {
    padding: 24px 28px;
  }

  @include bp-xlarge {
    padding: 24px 64px;
  }
}

.c-other-outlets__heading {
  text-align: center;
  margin-bottom: 24px;
  width: 100%;
}

.c-other-outlets__outlet {
  font-size: 14px;
  width: 50%;

  @include bp-xsmall {
    font-size: 16px;
  }

  @include bp-small {
    font-size: 18px;
  }

  @include bp-medium {
    width: 100%;
  }

  @include bp-large {
    font-size: 17px;
    width: 50%;
  }

  @include bp-xlarge {
    font-size: 18px;
  }
}
