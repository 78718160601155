.c-collapsible {
  outline: 0;
  display: none;
  
  &.is-visible {
    display: block;
  }

  .no-js & {
    display: block; 
  }
}
