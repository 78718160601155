.c-articles-index-categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.c-articles-index-category {
  align-items: center;
  border-radius: 56px;
  border: 1px solid #a7a7a7;
  color: #a7a7a7;
  display: inline-block;
  flex-grow: 0;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  padding: 6px 16px;
  margin: 0 4px 16px;
  text-align: center;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-charcoal;
    text-decoration: none;
  }

  @include bp-small {
    font-size: 18px;
    margin: 0 8px 16px;
    padding: 6px 24px;
  }

  @include bp-medium {
    font-size: 20px;
    margin: 0 10px 16px;
    min-width: 148px;
    padding: 10px 32px;
  }

  @include bp-large {
    min-width: 172px;
    padding: 10px 32px;
  }

  &:first-child {
    @include bp-medium {
      min-width: 120px;
    }

    @include bp-large {
      min-width: 144px;
    }
  }

  .c-recipes-preview__tabs.is-visible & {
    opacity: 1;
  }
}

.c-articles-index-category--active {
  background: $color-blue;
  border-color: $color-blue;
  color: $color-white;
  opacity: 1;
  position: relative;
  z-index: 1;

  &:hover,
  &:focus {
    color: $color-white;
  }
}
