.c-text-link {
  border: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  padding: 0;
  text-decoration: underline;

  &,
  &:hover,
  &:active,
  &:focus {
    
  }
}

.c-text-link--danger {
  color: red;
}

.c-text-link--primary {
  color: blue;
}
