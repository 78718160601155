.c-paging-links {
  margin: 24px 0;
  text-align: left;

  @include bp-medium {
    text-align: left;
  }
}

.c-paging-links--center {
  text-align: center;
}
