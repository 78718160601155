/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $color-blue;
  font-family: $font-headings;
  font-weight: 700;
  line-height: 1.2;

  strong {
    font-weight: 700;
  }
}

h1, .h1 {
  font-size: 1.75rem; // 28px

  @include bp-medium {
    font-size: 2.5rem; // 40px  
  }
}

h2, .h2 {
  font-size: 1.5rem; // 24px

  @include bp-medium {
    font-size: 2rem; // 32px
  }
}

h3, .h3 {
  font-size: 1.375rem; // 22px

  @include bp-medium {
    font-size: 1.75rem; // 28px
  }
}

h4, .h4 {
  font-size: 1.25rem; // 20px

  @include bp-medium {
    font-size: 1.5rem; // 24px
  }
}

h5, .h5 {
  font-size: 1.125rem; // 18px

  @include bp-medium {
    font-size: 1.25rem;
  }
}

h6, .h6 {
  font-size: 1rem; // 16px
}
