.c-site-wrapper {
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include ie {
    display: block;
  }
}
