.c-simple-article-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding:16px ;
  position: relative;
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  text-decoration: none;
}

.c-simple-article-card__subtitle {
  text-transform: uppercase;
  font-size: 13px;
}

.c-simple-article-card__heading {
  font-size: 15px;
}

.c-simple-article-card__stats {
  text-align: right;
}

.c-simple-article-card__time {
  font-size: 14px;
}
