/* ==========================================================================
   #ALIGN
   ========================================================================== */

.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-align-flex-end {
  align-items: flex-end;
}

.u-align-center {
  align-items: center;
}

.u-space-between {
  justify-content: space-between;
}

.u-justify-center {
  justify-content: center;
}
