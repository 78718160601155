@font-face {
  font-family: HKGrotesk;
  font-weight: 400;
  font-style: normal;
  src: url('/dist/assets/fonts/HKGrotesk/HKGrotesk-Regular.woff') format('woff');
}

@font-face {
  font-family: HKGrotesk;
  font-weight: 400;
  font-style: italic;
  src: url('/dist/assets/fonts/HKGrotesk/HKGrotesk-Italic.woff') format('woff');
}

@font-face {
  font-family: HKGrotesk;
  font-weight: 500;
  font-style: normal;
  src: url('/dist/assets/fonts/HKGrotesk/HKGrotesk-Medium.woff') format('woff');
}

@font-face {
  font-family: HKGrotesk;
  font-weight: 500;
  font-style: italic;
  src: url('/dist/assets/fonts/HKGrotesk/HKGrotesk-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: HKGrotesk;
  font-weight: 600;
  font-style: normal;
  src: url('/dist/assets/fonts/HKGrotesk/HKGrotesk-SemiBold.woff') format('woff');
}

@font-face {
  font-family: HKGrotesk;
  font-weight: 600;
  font-style: italic;
  src: url('/dist/assets/fonts/HKGrotesk/HKGrotesk-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: HKGrotesk;
  font-weight: 700;
  font-style: normal;
  src: url('/dist/assets/fonts/HKGrotesk/HKGrotesk-Bold.woff') format('woff');
}

@font-face {
  font-family: HKGrotesk;
  font-weight: 700;
  font-style: italic;
  src: url('/dist/assets/fonts/HKGrotesk/HKGrotesk-BoldItalic.woff') format('woff');
}

@mixin font-heading {
  font-family: $font-headings;
}

@mixin font-standard {
  font-family: $font-standard;
  text-transform: none;
}
