.c-show-hide__default {
  display: inline;
}

.c-show-hide__alternative {
  display: none;
}

.c-show-hide.is-expanded,
.c-show-hide.is-showing,
.c-show-hide.is-visible,
.c-show-hide.is-active {
  .c-show-hide__default {
    display: none;
  }

  .c-show-hide__alternative {
    display: inline;
  }
}
