.c-mobile-nav-toggle {
  background: transparent;
  color: $color-white;
  border: 0;
  font-family: inherit;
  font-size: 15px;
  font-weight: 700;
  outline: 0;
  padding: 24px 16px;
  text-transform: uppercase;
  z-index: 2;
  appearance: none;

  &.is-active {
    position: fixed;
    top: 0;
  }

  @include bp-small {
    padding: 24px;
  }

  @include bp-large {
    display: none;
  }

  .c-header--ready & {
    padding: 16px 16px;

    @include bp-small {
      padding: 16px 24px;
    }

    @include bp-medium {
      padding: 24px;
    }
  }

  .icon-nav-cross {
    font-size: 22px;
    font-weight: normal;
  }
}

.c-mobile-nav-toggle__menu {
  display: flex;
  align-items: center;
  text-align: left;
}

.c-mobile-nav-toggle__text {
  font-size: 13px;
  margin-left: 6px;
}

.c-mobile-nav-toggle__hamburger {
  display: block;
  height: 18px;
  position: relative;
  margin: 2px 0;
  width: 22px;
}

.c-mobile-nav-toggle__strokes {
  display: block;
}

.c-mobile-nav-toggle__strokes,
.c-mobile-nav-toggle__strokes:before,
.c-mobile-nav-toggle__strokes:after {
  background: $color-white;
  height: 2px;
}

.c-mobile-nav-toggle__strokes:before,
.c-mobile-nav-toggle__strokes:after {
  content: '';
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.c-mobile-nav-toggle__strokes:before {
  top: 8px;
}

.c-mobile-nav-toggle__strokes:after {
  top: 16px;
}
