.c-article-card {
  background-color: #fff;
  border-radius: $global-radius;
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  transition: box-shadow ease $global-transition-rate;


  @include ie {
    display: block;
    min-height: 1px;
  }

  &:hover,
  &:focus {
    box-shadow: 0 8px 24px -4px #e3e3e3;
    text-decoration: none;
  }
}

.c-article-card__content {
  background-color: $color-light-beige;
  border-bottom: 3px solid $color-white;
  flex-grow: 1;
  padding: 16px 24px;

  @include bp-medium {
    padding: 24px;
  }
}

.c-article-card__subtitle {
  color: $color-blue;
  font-weight: bold;
  line-height: 1;
  font-size: 13px;
  margin-bottom: 12px;
}

.c-article-card__stats {
  align-items: center;
  background-color: $color-light-beige;
  border-bottom-right-radius: $global-radius;
  border-bottom-left-radius: $global-radius;
  color: #807f7f;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  padding: 16px 20px;
  text-align: left;

  @include bp-medium {
    padding: 16px 24px;
  }
}

.c-article-card__cta {
  color: $color-blue;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;

  @include bp-small {
    font-size: 18px;
  }
}

.c-article-card__heading {
  @include font-standard;
  color: $color-charcoal;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 16px;

  @include bp-medium {
    font-size: 20px;
  }

  @include bp-large {
    font-size: 25px;
  }
}

.c-article-card__image {
  border-top-left-radius: $global-radius;
  border-top-right-radius: $global-radius;
  height: auto;
  width: 100%;
}

.c-article-card__top {
  background-color: $color-white;
  position: relative;
  text-align: right;
}

.c-article-card__tag {
  background-color: $color-blue;
  border-top-right-radius: $global-radius;
  color: $color-white;
  top: 0;
  right: 0;
  font-size: 13px;
  font-weight: bold;
  padding: 4px 8px;
  position: absolute;
  text-transform: uppercase;

  &:first-child {
    position: static;
  }
}

.c-article-card__time {
  color: $color-dark-grey;
  font-size: 15px;
  font-weight: 500;  
}
