/* ==========================================================================
   #TABLE
   ========================================================================== */

/**
 * A simple object for manipulating the structure of HTML `table`s.
 */

.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */

/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */

.o-table--fixed {
  table-layout: fixed;
}

table {
  th {
    background-color: $color-light-beige;
    border: 1px solid #000;
    font-weight: bold;
    min-width: 190px;
    padding: 12px 16px;
    vertical-align: middle;
  }

  td {
    border: 1px solid #000;
    padding: 12px 16px;
  }

  tbody tr {
    vertical-align: top;
  }
}

.c-table-wrapper {
  margin-left: -16px;
  margin-right: 16px;
  overflow: scroll;
  padding: 0 16px;
  width: calc(100% + 32px);
}
