.c-share-article {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @include bp-large {
    margin-bottom: 16px;
  }

  @include bp-large {
    display: block;
    position: absolute;
    top: 0;
    left: calc((900px - 720px) / -2);
    margin-bottom: 0;
  }
}

.c-share-article__label {
  font-size: 13px;
  font-weight: bold;
  margin-right: 8px;
  
  @include bp-large {
    margin-right: 0;
    margin-bottom: 4px;
  }
}

.c-share-article__link {
  background-color: #eee;
  border: 1px solid #ddd;
  display: block;
  height: 40px;
  margin-right: 8px;
  width: 40px;

  @include bp-large {
    margin-right: 0;
    margin-bottom: 16px;
  }
}
