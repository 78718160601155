.c-articles-search {
  display: flex;
  justify-content: center;
  margin: 32px auto;
  max-width: 600px;
  min-width: 260px;
  position: relative;
  width: 100%;

  @include bp-small {
    margin-bottom: 32px;
  }

  @include bp-medium {
    margin-bottom: 48px;
  }

  @include bp-large {
    margin-bottom: 64px;
  }
}

.c-articles-search__input {
  @include search-input;
  flex: 1;
}

.c-articles-search__button {
  @include search-button;
}

.c-articles-search__reset {
  font-size: 13px;
  position: absolute;
  right: 64px;
  text-decoration: none;
  text-transform: uppercase;
  top: 15px;
}
