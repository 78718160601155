/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background-color: $color-charcoal;
  padding-bottom: 32px;
  padding-top: 32px;

  @include bp-medium {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  @include bp-large {
    padding-bottom: 72px;
    padding-top: 72px;
  }
}

.c-footer__top {
  text-align: center;

  @include bp-medium {
    align-items: center;
    display: flex;
    justify-content: space-between;
    text-align: left;
  }
}

.c-footer__cta {
  box-shadow: 0 4px 8px rgba(#000, 0.33);
  white-space: nowrap;
}

.c-footer__bottom {
  border-top: 2px solid #3a3a3a;
  margin-top: 40px;
  text-align: center;
  padding-top: 40px;

  @include bp-medium {
    text-align: left;
  }

  @include bp-large {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }
}

.c-footer__about {
  margin-bottom: 32px;

  @include bp-medium {
    align-items: center;
    display: flex;
    margin-right: 48px;
  }

  @include bp-large {
    margin-bottom: 0;
    max-width: 760px;
  }
}

.c-footer__brand {
  color: #a0a0a0;
  font-size: 20px;
  font-weight: 700;
}

.c-footer__site-description,
.c-footer__site-disclaimer {
  color: #727272;
  font-size: 14px;
  line-height: 1.4;

  @include bp-xlarge {
    font-size: 15px;
  }

  @include bp-xlarge {
    font-size: 16px;
  }
}

.c-footer__site-disclaimer {
  color: #727272;
}

.c-footer__site-description {
  margin-top: 24px;
  margin-bottom: 0;

  @include bp-medium {
    line-height: 1.4;
    margin-top: 0;
    margin-left: 24px;
  }

  @include bp-xlarge {
    font-size: 15px;
  }

  @include bp-xlarge {
    font-size: 16px;
  }
}

.c-footer__logo-wrapper {
  flex-shrink: 0;
}

.c-footer__logo {
  display: block;
  opacity: 0.35;
  margin: 0 auto 24px;
  margin-bottom: 24px;

  @include bp-small {
    display: inline-block;
    margin-left: 24px;
    margin-bottom: 0;
  }

  &:first-child {
    @include bp-small {
      margin-left: 0;
    }
  }
}
