.c-page-banner-wrapper {
  position: relative;
}

.c-page-banner {
  background-color: $color-charcoal;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  
  @include ie {
    // min-height flex hack for ie
    height: 0;
  }

  @include bp-medium {
    min-height: 380px;

  }
}

.c-page-banner-wrapper--home {
  margin-top: 40px;

  @include bp-large {
    margin-top: 48px;
  }

  .c-page-banner {
    min-height: 360px;

    @include ie {
      // min-height flex hack for ie
      height: 0;
    }

    @include bp-medium {
      min-height: 400px;
    }

    @include bp-large {
      min-height: 480px;
    }
  }
}

.c-page-banner__picture {
  display: none;
}

.c-page-banner__image {
  display: block;
  width: 100%;
}

.c-page-banner__logo {
  @include bp-large {
    height: 110px;
  }
}

.c-page-banner__logo-wrapper {
  margin: 0 auto;
  position: relative;
  width: 80px;

  @include bp-medium {
    width: 104px;
  }

  @include bp-large {
    display: none;
  }
}

.c-page-banner__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  line-height: 1.35;
  margin: 0 auto;
  padding: 104px 0 24px;
  position: relative;
  text-align: center;
  width: 90%;
  z-index: 1;

  @include bp-large {
    padding: 144px 140px 24px;
    width: 100%;
  }
}

.c-page-banner__copy {
  color: $color-white;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.3;
  margin: 0 auto 16px;
  max-width: 800px;
  text-shadow: 0 2px 4px #000, 0 2px 8px rgba(#000, 0.5), 0 2px 20px #000;
  width: 100%;

  @include bp-xsmall {
    font-size: 15px;
  }

  @include bp-medium {
    font-size: 18px;
    margin-bottom: 24px;
  }

  @include bp-xlarge {
    font-size: 20px;
  }
}

.c-page-banner__cta {
  box-shadow: $global-btn-shadow;
  display: inline-block;
  margin-bottom: 24px;

  @include bp-medium {
    margin-bottom: 32px;
  }
}
