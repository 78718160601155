/* ==========================================================================
   #HIDE
   ========================================================================== */

/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.u-hidden-visually {
  @include hidden-visually();
}

/**
 * Hide visually and from screen readers.
 */

.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  @include bp-medium {
    display: none !important;
  }
}

.u-visible\@medium {
  display: none;

  @include bp-medium {
    display: block;
  }
}

.u-visible\@large {
  display: none;

  @include bp-large {
    display: block;
  }
}

.u-visible\@xlarge {
  display: none;

  @include bp-xlarge {
    display: block;
  }
}

[v-cloak] {
  display: none;
}
